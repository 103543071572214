import React from "react";
import { RootState, useAppDispatch } from "../redux/store";
import { withFormik, FormikProps, FormikErrors, Form, Field } from "formik";
import { addPatient } from "../redux/OsteroCheckSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import ProgressBar from "../components/ProgressBar";

interface PatientInfoFormikProps {}

type FormValues = {
  name: string;
  age: number;
  sex: string;
  mod_study:string;
  phone_no: string;
  ref_doc: string;
  history: string;
};

const PatientInfo = () => {
  const patientData = useSelector(
    (state: RootState) => state.OsteroCheck.patientData
  );
  const appDispatch = useAppDispatch();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [selectedButton, setSelectedButton] = React.useState("");
  // const modstudy = ["ChestR", "SXR", "MRI spine", "CT brain", "KXR"];

  const PatientInfoForm = (props: FormikProps<FormValues>) => {
    const { touched, errors, isSubmitting, setFieldValue } = props;
    return (
      <Form className="w-full text-center">
        <div className="flex flex-wrap -mx-3 mb-6 sm:mb-0">
          <div className="w-full px-3 mb-6 md:mb-0 text-left sm:mb-0">
            <label
              className="block tracking-wide text-gray-500 text-sm  mb-1"
              htmlFor="name">
              Patient Name
            </label>
            <Field
              type="text"
              name="name"
              className="appearance-none block w-full  text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="name"
              placeholder="Enter Patient Name"
            />
            {touched.name && errors.name && (
              <p className="text-red-500 text-xs italic">{errors.name}</p>
            )}
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6  text-left">
          <div className="w-full md:w-1/4 px-3 mb-3 md:mb-0">
            <label
              className="block tracking-wide text-gray-500 text-sm  mb-1"
              htmlFor="age">
              Age
            </label>
            <input
              type="number"
              min="1"
              max="100"
              name="age"
              className="appearance-none block w-full  text-gray-700 border mb-3 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="age"
              onChange={(e) => setFieldValue("age", Number(e.target.value))}
              placeholder="Age"
            />
            {touched.age && errors.age && (
              <p className="text-red-500 text-xs italic">{errors.age}</p>
            )}
          </div>

          <div className="w-full md:w-1/4 px-3 mb-3 md:mb-0">
            <label
              className="block tracking-wide text-gray-500 text-sm  mb-1"
              htmlFor="sex">
              Gender
            </label>
            <div className="relative">
              <Field
                as="select"
                name="sex"
                className="block appearance-none w-full  border border-gray-200 mb-3 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="sex">
                <option disabled value="">
                  {" "}
                  -{" "}
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Field>
              {touched.sex && errors.sex && (
                <p className="text-red-500 text-xs italic">{errors.sex}</p>
              )}

              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-2/4 px-3 mb-3 md:mb-0">
            <label
              className="block tracking-wide text-gray-500 text-sm mb-1"
              htmlFor="phone_no">
              Phone Number
            </label>
            <Field
              type="text"
              name="phone_no"
              className="appearance-none block w-full  text-gray-700 border border-gray-200 mb-3 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="phone_no"
              placeholder="Phone Number"
            />
            {touched.phone_no && errors.phone_no && (
              <p className="text-red-500 text-xs italic">{errors.phone_no}</p>
            )}
          </div>
          {/* <div className="w-full md:w-full px-3 mb-3 md:mb-0 sm:mb-0">
            <label
              className="block tracking-wide text-gray-500 text-sm  mb-1 sm:mb"
              htmlFor="sex">
              Select the Modality
            </label>
            <div className="relative">
              <Field
                as="select"
                name="mod_study"
                className="block appearance-none w-full  border border-gray-200 mb-3 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="mod_study">
                <option disabled value="">
                  {" "}
                  -{" "}
                </option>
                <option value="ChestR">XRAY Radiograph Chest</option>
                <option value="SXR">XRAY Radiograph Spine</option>
                <option value="MRI spine">Contrast MRI Spine</option>
                <option value="CT brain">CT Brain plain</option>
                <option value="KXR">XRAY Radiograph knee</option>
              </Field>
              

              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div> */}
        </div>
        <div className="flex flex-wrap -mx-3 mb-6  text-left">
          <div className="w-full px-3">
            <label
              className="block tracking-wide text-gray-500 text-sm mb-1"
              htmlFor="ref_doc">
              Referring Doctor
            </label>
            <Field
              type="text"
              name="ref_doc"
              className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="ref_doc"
              placeholder="Enter Referring doctor"
            />
            {touched.ref_doc && errors.ref_doc && (
              <p className="text-red-500 text-xs italic">{errors.ref_doc}</p>
            )}
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6  text-left">
          <div className="w-full px-3">
            <label
              className="block tracking-wide text-gray-500 text-sm mb-1"
              htmlFor="history">
              Patient History
            </label>
            <Field
              as="textarea"
              id="history"
              className="appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              name="history"
              rows={4}
            />
            {touched.history && errors.history && (
              <p className="text-red-500 text-xs italic">{errors.history}</p>
            )}
          </div>
        </div>

        <button
          className="bg-logo-blue hover:text-black  hover:bg-gray-300 text-white font-bold py-2 px-4 rounded"
          type="submit"
          disabled={isSubmitting}>
          Next
        </button>
      </Form>
    );
  };

  const PatientInfoFormik = withFormik<PatientInfoFormikProps, FormValues>({
    mapPropsToValues: (props) => {
      return {
        name: patientData.data.name,
        sex: patientData.data.sex,
        age: 0,
        phone_no: patientData.data.phone_no,
        ref_doc: patientData.data.ref_doc,
        mod_study:patientData.data.mod_study||'',
        history: patientData.data.history || "NA"
      };
    },

    validate: (values: FormValues) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.name) {
        errors.name = "Please fill out this field.";
      }
      if (!values.sex) {
        errors.sex = "Please fill out this field.";
      }
      if (!values.age) {
        errors.age = "Please fill out this field.";
      }
      if (!values.phone_no) {
        errors.phone_no = "Please fill out this field.";
      }
      if (!values.ref_doc) {
        errors.ref_doc = "Please fill out this field.";
      }
      return errors;
    },

    handleSubmit: (values) => {
      appDispatch(addPatient(values));
      navigate("/upload-scan");
    }
  })(PatientInfoForm);

  // const sendButtonNameToBackend = (buttonName: string) => {
  //   setSelectedButton(buttonName);
  //   dispatch(modStudy(buttonName));
  //   console.log(buttonName);
  // };

  return (
    <div className="flex justify-center h-screen w-screen py-4 px-8 sm:py-2 sm:px-2">
      <div className="h-full w-full">
        <div className="h-1/12">
          <Navbar />
        </div>

        <div className=" bg-white pt-4  grid grid-cols-3 grid-rows-4  gap-x-6 h-11/12 ">
          {/* <div
            className="col-span-1 row-span-1 shadow-md rounded
     rounded-2xl  p-2 pb-6 overflow-x-auto overflow-y-auto grid justify-center items-center
   bg-clean-white border box-border  shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border-gray-300">
            <p className="font-bold pb-4">
              Tests selected
            </p>
            <button className="bg-white shadow-lg  border text-black font-bold py-2 px-4 ">
              Bionic
            </button>      
          </div> */}

          <div
            className="col-span-3  row-span-4 shadow-md rounded h-full
     rounded-2xl  py-8 px-16 bg-clean-white
   border box-border  shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border-gray-300 overflow-y-auto sm:px-4">
            <div className="flex-col flex gap-4 mb-8">
              <div className="flex text-dark-blue-60 gap-2 items-center">
                <img
                  className=" overflow-hidden h-3 w-4"
                  alt=""
                  src="/img/back.svg"
                />
                <div
                  className="font-semibold inline-block"
                  onClick={() => navigate("/list")}>
                  Back
                </div>
              </div>
              <ProgressBar
                patient_info={{ isDone: true, loading: 0 }}
                upload_scan={{ isDone: false, loading: 0 }}
                report={{ isDone: false }}
              />

              <p className="font-bold mt-5 text-left text-lg">
                Patient Information
              </p>
            </div>
            <PatientInfoFormik />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
