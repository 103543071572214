import React, { useState, useRef, DragEvent } from "react";
import { RootState, useAppDispatch } from "../redux/store";
import { requestAddPatient, downloadReport } from "../redux/OsteroCheckSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
interface File {
  id: string;
  name: string;
  size: number;
  type: string;
  url: string;
}

const App: React.FC = () => {
  const navigate = useNavigate();
  const report = useSelector((state: RootState) => state.OsteroCheck.report);

  const patientData = useSelector(
    (state: RootState) => state.OsteroCheck.patientData
  );

  const appDispatch = useAppDispatch();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const newFiles: File[] = Array.from(event.target.files).map((file) => ({
        id: file.name,
        name: file.name,
        size: file.size,
        type: file.type,
        url: URL.createObjectURL(file)
      }));

      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleFileDelete = (fileId: string) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.id !== fileId)
    );
  };

  const handleUpload = async () => {
    const formData = new FormData();

    await Promise.all(
      selectedFiles.map(async (file) => {
        try {
          const response = await fetch(file.url);
          const blob = await response.blob();

          formData.append("image", blob, file.name);
          console.log("file url", file.name)
        } catch (error) {
          console.error("Error fetching image data:", error);
        }
      })
    );

    // appDispatch(downloadReport(patientData.data.order_id));

    await appDispatch(
      requestAddPatient({ patient_data: patientData.data, formData: formData,})
    );

    if (!report.loading) {
      navigate("/reports");
    }
  };

  const handleCancel = () => {
    setSelectedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files) {
      const newFiles: File[] = Array.from(event.dataTransfer.files).map(
        (file) => ({
          id: file.name,
          name: file.name,
          size: file.size,
          type: file.type,
          url: URL.createObjectURL(file)
        })
      );

      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <article>
      {selectedFiles.length === 0 && (
        <div
          className="  flex flex-col justify-center items-center gap-y-2"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDragEnter={handleDragEnter}>
          <img className="self-center" alt="" src="/img/empty_image.svg" />
          <p className=" text-gray-900 flex flex-wrap ">
            Drag and Drop your Study or
          </p>
          <input
            type="file"
            multiple
            ref={fileInputRef}
            onChange={handleFileInputChange}
            className="hidden"
          />
          <button
            className="bg-logo-blue hover:text-black  hover:bg-gray-300 text-white font-semibold py-2 px-4 rounded"
            onClick={() => fileInputRef.current?.click()}>
            Upload 
          </button>
        </div>
      )}
      <ul className="grid gap-4 justify-center">
        {selectedFiles.length > 0 ? (
          selectedFiles.map((file) => (
            <li key={file.id} className="block p-1 h-96 w-96 sm:w-[21rem]">
              <article className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 cursor-pointer relative shadow-sm hover:bg-black hover:bg-opacity-40">
                {file.type.includes("image/") ? (
                  <img
                    src={file.url}
                    alt="upload preview"
                    className="img-preview object-contain w-96 h-full sticky rounded-md bg-fixed"
                  />
                ) : (
                  <div className="w-full h-full  flex items-center justify-center rounded-md hover:bg-black hover:bg-opacity-40">
                    <span className="text-xl">
                      {file.name.substring(0, 15) +
                        (file.name.length > 15 ? "..." : "")}
                    </span>
                  </div>
                )}
                <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
                  <h1 className="flex-1 ">{file.name}</h1>
                  <div className="flex">
                    <p className="p-1 text-md  text-gray-700">
                      {(file.size / 1024).toFixed(2)} KB
                    </p>

                    <button
                      className="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md"
                      onClick={() => handleFileDelete(file.id)}>
                      <svg
                        className="pointer-events-none fill-current w-4 h-4 ml-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24">
                        <path
                          className="pointer-events-none"
                          d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z"
                        />
                      </svg>
                    </button>
                  </div>
                </section>
              </article>
            </li>
          ))
        ) : (
          <li className="text-center text-gray-500 col-span-full">
            {/* No files selected. Drag and drop files or click to upload. */}
          </li>
        )}
      </ul>
      {selectedFiles.length > 0 && (
        <div className="flex justify-center my-2">
          <button
            className="px-3 py-1 bg-red-500 hover:bg-red-600 text-white focus:outline-none rounded"
            onClick={handleCancel}>
            Cancel
          </button>
          <button
            className=" px-3 py-1 bg-blue-500 hover:bg-blue-600 text-white ml-2 rounded focus:outline-none"
            onClick={handleUpload}>
            Submit
          </button>
        </div>
      )}
    </article>
  );
};

export default App;
